//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
      url("../../Fonts/Poppins/Poppins-Bold.ttf")
        format("truetype");
    font-weight: bold;
  }
  @font-face {
    font-family: "Poppins";
    src: local("Poppins"),
      url("../../Fonts/Poppins/Poppins-ExtraBold.ttf")
        format("truetype");
    font-weight: bolder;
  }
  @font-face {
    font-family: "Poppins";
    src: local("Poppins"),
      url("../../Fonts/Poppins/Poppins-Regular.ttf")
        format("truetype");
    font-weight: normal;
  }
 .img-cover {
  object-fit: cover;
 } 
.explicit {
  width: 1.2em;
  height: 1.2em;
}
.main-card {
    width: 1110px;
    min-height: 716px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 25px; 
}  
 .content {
min-height: 100% !important; 
}
.login-bg {
width: 536px;
height: 676px;
border-radius: 16px;
background: #F7F7F7 0% 0% no-repeat padding-box;
opacity: 1;
background-image: url("../../images/logo/dc-background-black.svg") !important;
background-position: center;
}
.navbar-light {
 background: transparent;
}
.header-navbar.navbar-shadow {
   box-shadow: none;
}
input[type=email] {
  text-transform: lowercase;
}
.font {
  font-family: "Poppins";
}
.primary-bg {
    background: #000000;
}
.text-grey {
    color: #F7F7F7;
}
.text-lorem {
  color: #ADADAD;
}
.ui-loader.show .overlay {
  background-color: transparent!important;
}
.Toastify__toast .toastify-body {
   padding-left: 0; 
}
.fs-11 {
  font-size: 11px;
}
.fs-16 {
  font-size: 16px;
}
.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}
.fs-26 {
  font-size: 26px;
}
.fs-20 {
  font-size: 20px;
}
.text-light-grey {
    color: #747474;
    font-size: 16px;
    font-family: "Poppins";
}
.primary-text {
    color: #000000 !important;
}
.bg-primary {
  background: #000000 !important;
}
.custom-navbar {
margin-left: 8rem;
margin-right: 8rem;
}
.card-demo {
    width: 1110px;
    height: 599px;
}
.creator-btn {
    width: 147px;
    height: 56px;
    border-radius: 28px;
    font-family: "Poppins";
    font-size: 16px;
}
.cancel-btn-bg {
  background: #B0B0B0;
}
.input-fields {
  min-width: 19.875rem;
}
::placeholder {
  text-transform: lowercase !important;
}
.fs-32 {
  font-size: 32px;
}
input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #f7f7f7;
}
.go-to-web-btn {
  width: 153px;
  height: 56px;
  border-radius: 28px;
  font-family: "Poppins";
  font-size: 16px;
}
.creator-hub {
color: #030303;
text-transform: uppercase;
font-size: 32px;
font-weight: bolder;
font-family: "Poppins";
}
.nav-titles {
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins";
  color: #000000;
}
::placeholder { 
  color: #B1BFC0;
  opacity: 1;
  text-transform: capitalize;
}
input {
  color: #141414 !important;
}
// Modal Css //
.modal .modal-header {
   background-color: transparent; 
}
.modal .modal-header .btn-close {
  padding: 1.2rem;
 box-shadow: none;
 background-color: transparent;
 transform: translate(0px, 0px) !important;
}
.dropdown-item:focus {
  background-color: transparent;
}
